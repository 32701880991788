export function dropdownSettings(){
  if(document.querySelector('.dropdownSettingsJs')){
    const buttons = document.querySelectorAll('.dropdown-settings__toggle');

    const handleClick = (button) => {
      const dropdown = button.parentNode.querySelector('.dropdown-settings__container');
      dropdown.classList.toggle('hidden');
      button.classList.toggle('active');

      // Adjust dropdown position to prevent overflow
      adjustDropdownPosition(dropdown, button);
    };

    const adjustDropdownPosition = (dropdown, button) => {
      const rect = dropdown.getBoundingClientRect();

      // Check if dropdown is overflowing the bottom of the page
      if (rect.bottom > window.innerHeight) {
        dropdown.style.top = 'auto';
        dropdown.style.bottom = `${button.offsetHeight}px`;
      } else {
        dropdown.style.top = `${button.offsetHeight}px`;
        dropdown.style.bottom = 'auto';
      }
    };

    const closeAllDropdowns = () => {
      const openDropdowns = document.querySelectorAll('.dropdown-settings__container:not(.hidden)');
      openDropdowns.forEach(dropdown => {
        dropdown.classList.add('hidden');
        dropdown.parentNode.querySelector('.dropdown-settings__toggle').classList.remove('active');
      });
    };

    buttons.forEach(button => {
      button.addEventListener('click', (event) => {
        event.stopPropagation();
        closeAllDropdowns();  // Close other open dropdowns
        handleClick(button);
      });
    });

    // Close dropdown when clicking outside
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.dropdown-settings')) {
        closeAllDropdowns();
      }
    });
  }
}

export default dropdownSettings;