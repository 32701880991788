/**
 * Subscription modal
 */

export function subscriptionModal() {
	if (jQuery("#subscribForm").length == 0) {
		return;
	}

	jQuery('#subscribForm').validator();

	// - Prevent pop-up if form is invalid
	jQuery(".btn__modal").on("click", function (e) {
		e.preventDefault();
		jQuery('#subscriptionModal').modal("show");
	});

	jQuery(document).on('input', '#signupEmail', function () {
		var email = jQuery('#signupEmail').val();
		jQuery('.btn__submit').prop('disabled', true);
		if (isValidEmail(email)) {
			//enable the submit button
			jQuery('.btn__submit').prop('disabled', false);
		} else {
			//disable the submit button
			jQuery('.btn__submit').prop('disabled', true);
		}
	}),
	// - Change modal view when send btn is clicked
	jQuery(document).on("click", "#submitSubscription", function (e) {

				e.preventDefault()
				var form = jQuery("#subscribForm");
				var topics = document.querySelectorAll('input[name="topic"]:checked');
				var areaKey = document.querySelector('input[name="area"]:checked');
				var topicsKeys = topics != null ? Array.prototype.map.call(topics, function(checkbox) {
					return checkbox.value;
			}) : [];
				$.ajax({
					url: "/umbraco/api/Subscribe/SubscribeToPage",
					contentType: "application/json",
					type: 'GET',
					dataType: 'json',
					data: {
						areaKey: areaKey != null ? areaKey.value : "",
						topicKeys: topicsKeys.join(","),
						memberPageId: form.data("memberpageid"),
						email: jQuery("#signupEmail").val(),
						lang: document.documentElement.getAttribute('lang')

					},
					error: function (data) { // eslint-disable-next-line no-console
						console.log("Error: ", data)
						showMessage(false)
					},
					success: function (data) {
						showMessage(data)
					}
				});
			}
);

	//TODO UMB9 Success with small s !!
	function showMessage(response) { // - If success
		var form = jQuery("#subscribForm").parent().parent();
		console.log(form)
		console.log(jQuery(form).find(".message-body__error"))
		if ((response.success && response.isNew) || (response.success && !response.isApproved)) {
			form.find(".message-body__error").hide();
			form.find(".message-body__isMember").hide();
			form.find(".message-body__conformation").show();
		} else if (response.success && response.isApproved) {
			form.find(".message-body__error").hide();
			form.find(".message-body__conformation").hide();
			form.find(".message-body__isMember").show();
		} else {
			form.find(".message-body__isMember").hide();
			form.find(".message-body__conformation").hide();
			form.find(".message-body__error").show();
			// - Run animate function
		}
		animate();
	}

	// - Animate modal slide
	function animate() {
		jQuery('.modal-content').animate({
			height: jQuery('.modal-content').outerHeight()
		}, 600);

		jQuery('.message-body').removeClass("modal-body__hidden");

		jQuery('.subscription-body').animate({
			"margin-top": "-" + jQuery('.subscription-body').outerHeight() + "px"
		}, 600, function () {
			jQuery('.subscription-body').addClass("modal-body__hidden")
		});
	}

	// Close modal when close btns are clicked
	jQuery("button.close").on("click", function () {
		setTimeout(function () {
			jQuery('.subscription-body').css("margin-top", "0px");
			jQuery('.subscription-body').removeClass("modal-body__hidden");
			jQuery('.message-body').addClass("modal-body__hidden")
		}, 1000);
	});

	// Return to previous modal when error btn is clicked
	jQuery(".message-body__error button").on("click", function () {
		jQuery('.modal-content').animate({
			height: jQuery('.modal-content').outerHeight()
		}, 600);

		jQuery('.subscription-body').removeClass("modal-body__hidden");

		jQuery('.subscription-body').animate({
			"margin-top": "0px"
		}, 600, function () {
			jQuery('.message-body.error').addClass("modal-body__hidden")
		});
	});

	jQuery(document).on('input', '#emailInput', function () {

		validateForm();
	})
	function validateForm() {
		var email = jQuery('#emailInput').val();
		jQuery('.btn__submit').prop('disabled', true);
		if (isValidEmail(email) && jQuery('#consent').is(':checked')) {
			//enable the submit button
			jQuery('.btn__submit').prop('disabled', false);
		} else {
			//disable the submit button
			jQuery('.btn__submit').prop('disabled', true);
			//turn off hover effects
		}
	}
	function isValidEmail(email) {
		const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
		return emailRegex.test(email);
	}
}

export default subscriptionModal;
