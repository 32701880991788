export function grayscale() {
  const body = document.querySelector('body');
  const storageKey = 'colorTheme';
  const grayscaleClass = 'grayscale';
  const grayscaleBtnElements = document.querySelectorAll('.jsGrayscaleToggle');

  // Get the theme preference (grayscale or regular)
  const getSelectedTheme = () => localStorage.getItem(storageKey);

  // Set theme helper
  const setSelectedTheme = (colorTheme) => localStorage.setItem(storageKey, colorTheme);

  // Initialize grayscale state based on stored preference
  const grayscaleInitState = () => {
    if (getSelectedTheme() === grayscaleClass) {
      body.classList.add(grayscaleClass);
      grayscaleBtnElements.forEach(item => item.classList.add('active'));
    }
  };
  grayscaleInitState();

  // Toggle grayscale theme on or off on button click
  grayscaleBtnElements.forEach(button => {
    button.addEventListener('click', () => {
      const isGrayscaleActive = body.classList.toggle(grayscaleClass);

      // Update all buttons to reflect the current state
      grayscaleBtnElements.forEach(item => {
        if (isGrayscaleActive) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      });

      // Update the theme in local storage
      if (isGrayscaleActive) {
        setSelectedTheme(grayscaleClass);
      } else {
        setSelectedTheme('regular');
      }
    });
  });
}

export default grayscale;